import React, { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Header, LoginHeader } from "../../components/Header";
import UserContext from "../../context/User";
import useStyle from "./styles";
import TextComponent from "./TextComponent";
import Heading from "./Heading";
import Footer from "../../components/Footer1/Footer";

const Klarna = () => {
  let initialText =
    "We are pleased to announce our partnership with Klarna, offering you flexible payment options at checkout. You can choose to pay in full immediately or spread the cost of your Borku order over three instalments—giving you the freedom to decide what works best for you.";
  let payNowText =
    "Make a full payment at the time of placing your order. Please note that a minimum order value of £30 is required when using Klarna.";
  let Pay3Inst =
    "Place your order today and spread the cost over three instalments. The first payment will be processed at the time of purchase, with the remaining payments automatically scheduled every 30 days";
  let goodToKnow =
    "Klarna will send you an email confirmation and payment reminders when your instalments are due.You can conveniently manage all your payments through the Klarna app.";
  let payWithKlarna =
    "Browse and select your preferred restaurants and grocery stores on the Borku App as usual. At checkout, simply choose ‘Klarna’ as your payment method and follow the screen.";
  let payWithKlarnaConditions =
    "*Please shop responsibly. You must be 18 years or older to use Klarna. A minimum order value of £30 applies. Missed payments may result in fees and could impact your ability to useKlarna in the future. Approval is subject to status T&Cs apply.";
  let KlarnaReminder1 =
    "Please spend responsibly— splitting payments may not always be the best option for everyone. Ensure you plan ahead and have sufficient funds to make your repayments on time each month.";
  let KlarnaReminder2 =
    "For more information about Klarna's payment options, please visit their FAQs If you encounter any issues with your order, kindly contact Klarna Customer Service for assistance.";
  const classes = useStyle();
  const { isLoggedIn } = useContext(UserContext);
  return (
    <Grid>
      <Grid>
        {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
        <Box className={classes.imageContainer}>
          <Typography
            variant="h4"
            color="textPrimary"
            align="center"
            className={classes.title}
          >
            BORKU X KLARNA
          </Typography>
        </Box>
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item xs={10} sm={10} md={9} className={classes.main}>
            <TextComponent text={"Published: 2025"} className={classes.MV3} />

            <TextComponent text={initialText} />

            <Box>
              <Heading
                heading={"Ways to pay with "}
                center={true}
                big={true}
                klarna={true}
              />
              <Heading heading={"Pay Now"} number={"1."} />
              <TextComponent text={payNowText} />

              <Heading heading={"Pay in 3 Instalments"} number={"2."} />
              <TextComponent text={Pay3Inst} />

              <Heading heading={"Good to Know"} />
              <TextComponent text={goodToKnow} />
            </Box>

            <Box>
              <Heading
                heading={"How to pay with "}
                center={true}
                big={true}
                klarna={true}
              />
              <TextComponent text={payWithKlarna} />
              <Heading heading={"Terms & Conditions"} />
              <TextComponent text={payWithKlarnaConditions} />
            </Box>

            <Box>
              <Heading heading={"Reminder from "} big={true} klarna={true} />
              <TextComponent text={KlarnaReminder1} />
              <TextComponent text={KlarnaReminder2} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default Klarna;
