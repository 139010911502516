import React from 'react'
import { Box,Typography } from "@mui/material";
import useStyle from "./styles";

const Heading = ({heading,center=false,big=false,number,klarna}) => {
  const classes = useStyle();
  return (
   <Box  style={{textAlign: center ? "center" : "left",marginBottom: big ? "20px" :"0px" }} >
    
    <Typography variant="subtitle2" className={classes.boldText} style={{fontSize: big ?"30px" : "20px"}} >
    {number && 
    <Typography className={classes.number}>
    {number}

    </Typography>}
    {heading}
    {klarna && 
    <Typography className={classes.klar}>
      Klarna
      </Typography>
    
    }
    </Typography>
   </Box>
  )
}

export default Heading
