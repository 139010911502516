import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "flex",
        paddingTop: "70px",
        minWidth: "100%",
        backgroundColor: "transparent",
        color: theme.palette.secondary.light,
        marginBottom:"20px"
      },
    imageContainer: {
        width: "100%",
        height: "400px",
        backgroundImage:
          "url(https://images.deliveryhero.io/image/foodpanda/cms-hero.jpg?width=2000&height=500|https://images.deliveryhero.io/image/foodpanda/cms-hero.jpg?width=4000&height=1000)",
        backgroundSize: "cover",
        backgroundPositionX: "50%",
        backgroundPositionY: "center",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      },
      main:
      {
        margin:"0 auto"
      },
      MV3: {
        width: "100%",
        margin: theme.spacing(3, 0),
        marginTop:0,
      },
      MV2: {
        margin: theme.spacing(2, 0),
      },
      boldText: {
        fontWeight: 600,
        fontSize: "1rem",
      },
      heading:
      {
        textAlign:"center",
      },
      number:
      {
        display:"inline",
        marginEnd:"6px",
        fontWeight: 600,
        fontSize:"20px"
      },
      klar:
      {
        display:"inline",
         fontWeight: 600,
        fontSize:"30px",
        color:"#ffb3c7"
      }


}))

export default useStyles;