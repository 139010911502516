import React from 'react'
import { Box, Typography } from "@mui/material";
import useStyle from "./styles";

const TextComponent = ({text}) => {
  const classes = useStyle();
  return (
   <Box className={classes.MV3}>
    <Typography>
    {text}
    </Typography>
    
   </Box>
  )
}

export default TextComponent
